import type { FC } from 'react';
import React, { useEffect, useMemo, Fragment } from 'react';
import idx from 'idx';
import { useQuery } from '@apollo/react-hooks';

import type { ADDoc } from '@atlaskit/editor-common/validator';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { ErrorDisplay, isStatusCodeError } from '@confluence/error-boundary';
import { getTranslation } from '@confluence/i18n';
import { PageSegmentLoadStart } from '@confluence/browser-metrics';
import { markErrorAsHandled } from '@confluence/graphql';
import { fg } from '@confluence/feature-gating';
import { expValEquals } from '@confluence/feature-experiments';

import { ReadTimeComponent } from './ReadTimeComponent';
import type { ContentFeatures } from './featuresExtractor';
import { extractContentFeatures } from './featuresExtractor';
import { ReadTimeQuery } from './ReadTimeQuery.graphql';
import { ReadTimeSmartsQuery } from './ReadTimeSmartsQuery.graphql';
import type { ReadTimeQuery as ReadTimeQueryResponse } from './__types__/ReadTimeQuery';
import { READ_TIME_METRIC } from './perf.config';

const wpm = 265; // words per minute, inspired by Medium.com
const minute = 60;

type ReadTimeProps = {
	contentId: string;
};

const getAdf = (response: ReadTimeQueryResponse): ADDoc | null => {
	const representation = idx(response, (_) => _.content.nodes[0].body.dynamic.representation);
	if (representation === 'atlas_doc_format') {
		const adfString = idx(response, (_) => _.content.nodes[0].body.dynamic.value);
		if (adfString) {
			try {
				return JSON.parse(adfString);
			} catch {
				return null;
			}
		}
	}
	return null;
};

const calculateImageReadTime = (imageCount: number): number => {
	let readTime: number = 0;
	for (let i = 0; i < imageCount; i++) {
		readTime += Math.max(12 - i, 3);
	}
	return readTime;
};

const OriginalReadTime = ({ contentId }: { contentId: string }) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { data, loading, error } = useQuery(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		ReadTimeQuery,
		{
			variables: { contentId },
			fetchPolicy: 'cache-only',
		},
	);

	useEffect(() => {
		const adf = getAdf(data);
		if (!createAnalyticsEvent || !adf || loading || error || !contentId) {
			return;
		}
		const contentType = idx(data, (_) => _.content.nodes[0].type);
		createAnalyticsEvent({
			type: 'sendOperationalEvent',
			data: {
				source: 'confluence-frontend',
				action: 'extracted',
				actionSubject: 'contentFeature',
				objectType: contentType,
				objectId: contentId,
				attributes: extractContentFeatures(adf),
			},
		}).fire();
	}, [contentId, loading, data, error, createAnalyticsEvent]);

	const readTime = useMemo(() => {
		const adf = getAdf(data);
		if (!adf || loading || error || !contentId) {
			return null;
		}
		const features = extractContentFeatures(adf);
		return calculateEstimatedReadTime(features);
	}, [loading, error, data, contentId]);

	const locale = getTranslation().locale;
	const shouldRenderReadTime = locale && locale.substring(0, 2).toLowerCase() === 'en';

	if (readTime && shouldRenderReadTime) {
		return (
			<ReadTimeComponent
				readTime={readTime}
				pageId={contentId}
				isAbbreviatedReadTime={expValEquals(
					'cc-page-experiences-new-renderer-byline',
					'cohort',
					'test',
				)}
			/>
		);
	}

	return error ? <ErrorDisplay error={error} /> : null;
};

const SmartsReadTime = ({ contentId }: { contentId: string }) => {
	const locale = getTranslation().locale;
	const shouldRenderReadTime = locale && locale.substring(0, 2).toLowerCase() === 'en';

	const { data, loading, error } = useQuery(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		ReadTimeSmartsQuery,
		{
			variables: { id: contentId },
			errorPolicy: 'all',
			fetchPolicy: 'cache-first',
		},
	);

	const readTime = data?.getSmartContentFeature?.readTime;

	if (loading) {
		return null;
	}

	if (readTime == undefined || !shouldRenderReadTime || error) {
		if (isStatusCodeError(error, '403') || error?.message.includes('403')) {
			markErrorAsHandled(error);
			return null;
		}

		return (
			<Fragment>
				<OriginalReadTime contentId={contentId} />
				{error && <ErrorDisplay error={error} />}
			</Fragment>
		);
	}

	return (
		<ReadTimeComponent
			readTime={readTime}
			pageId={contentId}
			isAbbreviatedReadTime={expValEquals(
				'cc-page-experiences-new-renderer-byline',
				'cohort',
				'test',
			)}
		/>
	);
};

// Calculate Read time based on:
// 1. Average reading speed 265 WPM
// 2. Image count (1 sec added for each image until first 10 images post that 3 sec for each subsequent image)
export const calculateEstimatedReadTime = (features: ContentFeatures): number =>
	Math.ceil(
		((features.wordCount / wpm) * 60 + calculateImageReadTime(features.imageCount)) / minute,
	);

export const ReadTimeLoader: FC<ReadTimeProps> = ({ contentId }) => {
	return (
		<>
			<PageSegmentLoadStart metric={READ_TIME_METRIC} />
			<ReadTime contentId={contentId} />
		</>
	);
};

export const ReadTime: FC<ReadTimeProps> = ({ contentId }) => {
	if (fg('confluence_frontend_skip_smarts_read_time')) {
		return <OriginalReadTime contentId={contentId} />;
	}

	return <SmartsReadTime contentId={contentId} />;
};
